// TODO: Refactor it
// import type { ProductFull, ProductOfferFull } from '@/entities/product';


export default function getMetricProductNew({
  product,
  offer,
  categoryCode,
  isLiberty,
  isForceOfferIdToId,
}: {
  product: any,
  offer?: any | null,
  categoryCode?: string | null,
  isLiberty?: boolean,
  isForceOfferIdToId?: boolean,
}) {
  let productCategory;

  if (isLiberty) {
    if (categoryCode === 'light') {
      productCategory = 'Свет';
    }
    if (categoryCode === 'accessories') {
      productCategory = 'Аксессуары';
    }
    if (categoryCode === 'all-furniture') {
      productCategory = 'Мебель';
    }
  } else if (offer) {
    if (offer?.price?.price < 10000) {
      productCategory = 'Ковры до 10 тыс руб';
    } else if (offer?.price?.price < 30000) {
      productCategory = 'Ковры 10-30 тыс руб';
    } else if (offer?.price?.price < 50000) {
      productCategory = 'Ковры 30-50 тыс руб';
    } else if (offer?.price?.price < 100000) {
      productCategory = 'Ковры 50-100 тыс руб';
    } else if (offer?.price?.price < 150000) {
      productCategory = 'Ковры 100-150 тыс руб';
    } else if (offer?.price?.price < 200000) {
      productCategory = 'Ковры 150-200 тыс руб';
    } else if (offer?.price?.price < 500000) {
      productCategory = 'Ковры 200-500 тыс руб';
    } else if (offer?.price?.price >= 500000) {
      productCategory = 'Ковры от 500 тыс руб';
      // eslint-disable-next-line no-unsafe-optional-chaining
    } else if (Number.isNaN(+offer?.price?.price)) {
      productCategory = 'Ковры Цена по запросу';
    }
  }

  if (isLiberty) {
    const chunk = {
      name: product?.name,
      id: +product.id,
      description: product?.name,
      category: productCategory,
      price: (product as any)?.price ? (product as any).price?.price : null,
      brand: (product as any)?.brand ? (product as any).brand?.name : null,
      variant: product?.country?.name ?? null,
    };

    return chunk;
  }

  const chunk = {
    name: product.name,
    id: +product.id,
    category: productCategory,
    price: offer?.price?.price,
    brand: product?.collection?.name ?? null,
    variant: +(offer?.id ?? 0),
  };

  if (isForceOfferIdToId) {
    chunk.id = +chunk.variant;
  }

  return chunk;
}
